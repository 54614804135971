import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-react-intl';

import { Article } from '../../theme/typography';
import { Navigation } from '../../shared/components/navigation';
import { Footer } from '../../shared/components/footer';
import { ContactFormSection } from '../../shared/components/contactFormSection';

interface IndexProps {
  site: {
    buildTime: string;
  };
}

const Wytaczarki: React.FC<PageProps<IndexProps>> = () => {
  const intl = useIntl();
  return (
    <Article>
      <Navigation />
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={intl.formatMessage({ id: 'products.product_3.title' })}
        meta={[
          {
            name: 'description',
            content: intl.formatMessage({ id: 'products.product_3.image_alt' }),
          },
        ]}
      />
      <section className="bg-white body-font w-full">
        <div className="container px-5 pt-32 mx-auto flex flex-col">
          <div className="lg:w-5/6 mx-auto">
            <div className="rounded-lg mx-auto lg:w-4/6 h-1/2 overflow-hidden mb-8">
              <StaticImage
                className="object-cover object-center h-full w-full"
                src="../../images/mikrometryczny-przyrzad-do-wysuwania-noza.jpg"
                alt={intl.formatMessage({ id: 'products.product_3.image_alt' })}
              />
            </div>
            <h1 className="text-3xl font-extrabold md:text-4xl">
              {intl.formatMessage({ id: 'products.product_3.title' })}
            </h1>
            <div className="flex flex-col sm:flex-row">
              <div className="w-full sm:py-8 mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="mb-8 leading-relaxed text-gray-500">
                  {intl.formatMessage({ id: 'products.product_3.description' })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactFormSection />
      <Footer />
    </Article>
  );
};

export default Wytaczarki;
